import { useState } from "react"
import styled from "styled-components"

import { SelectedPlan } from "src/components/Account/BillingPortal/ChangePlan/ChangePlan"
import { useMinutEmployee } from "src/components/Account/BillingPortal/ChangePlan/useMinutEmployee"
import { planToCopy } from "src/components/Account/BillingPortal/ChangePlan/utils"
import { usePostSubscriptions } from "src/data/billing/queries/billingQueries"
import { usePostUserSubscription } from "src/data/subscription/queries/subscriptionQueries"
import { TPostSubscriptionError400 } from "src/data/subscription/types/subscriptionTypes"
import { useHomeTokens } from "src/hooks/useHomeTokens"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function CreateSubscriptionButton({
  selectedPlan,
  onSuccess,
  disabled,
  numberOfHomes,
  couponIds,
}: {
  selectedPlan: SelectedPlan | undefined
  onSuccess: () => void
  numberOfHomes: number | undefined
  disabled?: boolean
  couponIds: string[] | undefined
}) {
  const { t, langKeys } = useTranslate()
  const postSubscriptions = usePostSubscriptions()

  const minut = useMinutEmployee()
  const [couponOverride, setCouponOverride] = useState<string>("")
  const postUserSubscription = usePostUserSubscription()

  const { available: homeTokensFeatureAvailable } = useHomeTokens({
    useCachedData: false,
  })

  const isError = postSubscriptions.isError || postUserSubscription.isError
  const loading = postSubscriptions.isLoading || postUserSubscription.isLoading

  function createSubscription(planId: SelectedPlan["planId"]) {
    if (homeTokensFeatureAvailable) {
      return postUserSubscription.mutate(
        {
          plan_id: planId,
          coupon_ids: couponIds,
        },
        { onSuccess }
      )
    }

    return postSubscriptions.mutate(
      {
        subscriptionBody: {
          plan_id: planId,
          invoice_immediately: true,
          plan_quantity: (numberOfHomes || 0) > 0 ? numberOfHomes : 1, // number of homes might be 0 thus we need to default to at least 1
          coupon_ids: couponOverride ? [couponOverride] : couponIds,
        },
      },
      {
        onSuccess,
      }
    )
  }

  if (!selectedPlan) {
    return (
      <MButtonLegacy disabled size="large" fullWidth>
        {t(langKeys.change_plan)}
      </MButtonLegacy>
    )
  }

  return (
    <Box>
      {isError && (
        <ErrorAlert
          errorKey={postUserSubscription.error?.response?.data?.error_key}
        />
      )}

      {!!minut.eligible && (
        <input
          value={couponOverride}
          onChange={(e) => setCouponOverride(e.target.value)}
          placeholder={minut.coupon}
        ></input>
      )}

      <MButtonLegacy
        size="large"
        loading={loading}
        onClick={() => {
          createSubscription(selectedPlan.planId)
        }}
        fullWidth
        style={{ marginBottom: spacing.S }}
        disabled={disabled && !couponOverride}
      >
        {t(langKeys.change_plan_to, {
          plan_name: planToCopy(selectedPlan.plan),
        })}
      </MButtonLegacy>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

function ErrorAlert({
  errorKey,
}: {
  errorKey: TPostSubscriptionError400["error_key"] | undefined
}) {
  const { t, langKeys } = useTranslate()

  // Both invalid plan and inconsistent inactive tokens are used to indicate
  // that a user has tokens of a different type from what is being purchased
  // which is currently not supported
  if (
    errorKey === "inconsistent_inactive_tokens" ||
    errorKey === "invalid_plan"
  ) {
    return (
      <MBanner type="error">
        {t(langKeys.subscription_sign_up_failed_token_mismatch)}
      </MBanner>
    )
  }

  // Show general error message
  if (
    errorKey === "home_tokens_error" ||
    errorKey === "subscription_already_exists" ||
    errorKey === "plan_not_found"
  ) {
    return (
      <MBanner type="error">{t(langKeys.failed_something_went_wrong)}</MBanner>
    )
  }

  // Show general payment error message
  return (
    <MBanner type="error">
      {`${t(langKeys.payment_failed)}. ${t(
        langKeys.payment_failed_help_funds
      )}`}
    </MBanner>
  )
}
